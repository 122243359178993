const handleExportToTXT = (name: string, data: any) => {
  const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${name}.txt`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default handleExportToTXT;
