import { create } from "zustand";
import TamuModel from "@/types/TamuModel";

interface TamuStore {
  tamu: TamuModel;
  setTamu: (tamu: TamuModel) => void;
}

const useTamuStore = create<TamuStore>((set: any) => ({
  tamu: {
    id: null, 
    displayName: "Bapak/Ibu",
    confirmedHadir: false,
    hadir: false,
    pax: 0,
    undanganTerkirim: false,
    type: "",
    tamuDari: "",
    undanganFisik: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  setTamu: (tamu: TamuModel) => set((_: any) => ({ tamu: tamu })),
}));

export default useTamuStore;
