import { Button, Popover, Radio } from "antd";
import {
  ArrowUpOutlined,
  CloseOutlined,
  FilterOutlined,
  InfoOutlined,
  PlusOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import TambahTamuModal from "./TambahTamuModal";
import ListItem from "./ListTamuItem";
import TamuModel from "@/types/TamuModel";
import {
  db,
  collection,
  onSnapshot,
  // doc,
  // updateDoc,
} from "@/utility/firebase_options";
import debounce from "lodash/debounce";
import SummaryInfo from "./SummaryInfo";
import LiahtWishModal from "./LiahtWishModal";
import FilterTamuModal from "./FilterTamuModal";
import { useNavigate } from "react-router-dom";
import handleExport from "@/utility/exportToExcel";
import { mempelai, statusTamu } from "@/constants/mempelai";
import handleExportToTXT from "@/utility/exportToTXT";

const TamuList = () => {
  const navigate = useNavigate();
  const [isModalTambahOpen, setIsModalTambahOpen] = useState(false);
  const [isModalSummaryInfoOpen, setIsModalSummaryInfoOpen] = useState(false);
  const [isModalLihatWishOpen, setIsModalLihatWishOpen] = useState(false);
  const [tamu, setTamu] = useState<TamuModel[]>([]);
  const [filteredTamu, setFilteredTamu] = useState<TamuModel[]>([]);
  const [filterOption, setFilterOption] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const [isAtTop, setIsAtTop] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [tamuPerPage, _] = useState(15); // Jumlah komentar per halaman
  const [currentPage, setCurrentPage] = useState(0); // Halaman saat ini
  const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "tamu"), (snapshot) => {
      const data = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ); // Sort by newest
      setTamu(data as TamuModel[]);
      setLoading(false); // Set loading to false after data is fetched
    });

    return () => unsubscribe();
  }, []);

  const filterConditions = (tamu: TamuModel, searchValue: string) =>
    tamu.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
    tamu.pax.toString().includes(searchValue.toLowerCase());

  useEffect(() => {
    const handler = debounce(() => {
      let filteredTamu;
      switch (filterOption) {
        case "undanganTerkirim":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              tamu.undanganTerkirim && filterConditions(tamu, searchValue)
          );
          break;
        case "undanganBelumTerkirim":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              !tamu.undanganTerkirim && filterConditions(tamu, searchValue)
          );
          break;
        case "temanRizka":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              tamu.tamuDari === "2" &&
              tamu.type === "2" &&
              filterConditions(tamu, searchValue)
          );
          break;
        case "temanAdhitya":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              tamu.tamuDari === "1" &&
              tamu.type === "2" &&
              filterConditions(tamu, searchValue)
          );
          break;
        case "keluargaRizka":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              tamu.tamuDari === "2" &&
              tamu.type === "1" &&
              filterConditions(tamu, searchValue)
          );
          break;
        case "keluargaAdhitya":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              tamu.tamuDari === "1" &&
              tamu.type === "1" &&
              filterConditions(tamu, searchValue)
          );
          break;
        case "hadir":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              tamu.confirmedHadir &&
              tamu.hadir &&
              filterConditions(tamu, searchValue)
          );
          break;
        case "tidakHadir":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              !tamu.confirmedHadir &&
              !tamu.hadir &&
              filterConditions(tamu, searchValue)
          );
          break;
        case "tidakAdaStatus":
          filteredTamu = tamu.filter(
            (tamu: TamuModel) =>
              !tamu.type &&
              !tamu.tamuDari &&
              filterConditions(tamu, searchValue)
          );
          break;
        default:
          filteredTamu = tamu.filter((tamu: TamuModel) =>
            filterConditions(tamu, searchValue)
          );
          break;
      }
      setFilteredTamu(filteredTamu);
    }, 1000);

    handler();

    return () => {
      handler.cancel();
    };
  }, [filterOption, tamu, filterConditions, searchValue]);

  // const filterTamu = (
  //   filterOption: string,
  //   tamu: TamuModel[],
  //   filterConditions: (tamu: TamuModel, searchValue: string) => boolean,
  //   searchValue: string
  // ) => {
  //   let filteredTamu;
  //   switch (filterOption) {
  //     case "undanganTerkirim":
  //       filteredTamu = tamu.filter(
  //         (tamu) => tamu.undanganTerkirim && filterConditions(tamu, searchValue)
  //       );
  //       break;
  //     case "undanganBelumTerkirim":
  //       filteredTamu = tamu.filter(
  //         (tamu) => !tamu.undanganTerkirim && filterConditions(tamu, searchValue)
  //       );
  //       break;
  //     case "hadir":
  //       filteredTamu = tamu.filter(
  //         (tamu) => tamu.hadir && filterConditions(tamu, searchValue)
  //       );
  //       break;
  //     case "tidakAdaStatus":
  //       filteredTamu = tamu.filter(
  //         (tamu) => !tamu.type && !tamu.tamuDari && filterConditions(tamu, searchValue)
  //       );
  //       break;
  //     default:
  //       filteredTamu = tamu.filter((tamu) => filterConditions(tamu, searchValue));
  //       setIsFiltered(false);
  //       break;
  //   }
  //   setSearchValue(searchValue);
  //   setFilteredTamu(filteredTamu);
  //   setIsFiltered(true);

  //   return () => {
  //     setFilteredTamu(tamu);
  //     setIsFiltered(false);
  //   };
  // };

  // const updateTamu = () => {
  //   for (const data of tamu) {
  //     const docRef = doc(db, "tamu", data.id ?? "");
  //     updateDoc(docRef, {
  //       undanganTerkirim: false,
  //     })
  //       .then(() => {
  //         console.log("Document successfully written!");
  //       })
  //       .catch((error) => {
  //         console.error("Error writing document: ", error);
  //       });
  //   }
  // };

  const currentTamu = useMemo(() => {
    return filteredTamu.slice(0, (currentPage + 1) * tamuPerPage);
  }, [filteredTamu, currentPage, tamuPerPage]);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [containerRef, filteredTamu.length]);
  useEffect(() => {
    const handleScroll = (event: any) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      setIsAtTop(scrollTop === 0);

      if (
        scrollTop + clientHeight >= scrollHeight &&
        (currentPage + 1) * tamuPerPage < filteredTamu.length
      ) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [
    containerRef,
    loading,
    currentTamu,
    filteredTamu.length,
    tamuPerPage,
    currentPage,
  ]);

  return (
    <>
      <div className="relative wi-flex wi-flex-col wi-w-full wi-p-4 wi-container wi-mx-0 md:wi-mx-auto wi-h-screen">
        <h1
          className={`!wi-text-[2rem] md:!wi-text-[3rem]  wi-mb-2 wi-text-center wi-z-10 `}
        >
          Daftar Tamu
        </h1>
        <div
          className={` wi-grid-cols-1 md:wi-grid-cols-5 wi-gap-2 wi-w-full wi-mb-2 wi-border-primary wi-rounded-md wi-p-2 wi-hidden md:wi-grid`}
        >
          <div className="wi-flex wi-flex-col  !wi-font-buenard wi-border wi-border-primary wi-rounded-md wi-p-2">
            <p className="!wi-text-lg  !wi-font-bold !wi-text-secondary ">
              Total Keseluruhan:{" "}
            </p>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Undangan: </p>
              <p className="!wi-text-lg !wi-text-secondary ">{tamu.length}</p>
            </div>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary "> Pax: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu.reduce((acc, tamu) => acc + tamu.pax, 0)}
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col  !wi-font-buenard wi-border wi-border-primary wi-rounded-md wi-p-2">
            <p className="!wi-text-lg !wi-font-bold !wi-text-secondary ">
              Keluarga Adhitya{" "}
            </p>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Undangan: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {
                  tamu.filter(
                    (tamu) => tamu.tamuDari === "1" && tamu.type === "1"
                  ).length
                }
              </p>
            </div>

            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary "> Pax: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu
                  .filter((tamu) => tamu.tamuDari === "1" && tamu.type === "1")
                  .reduce((acc, tamu) => acc + tamu.pax, 0)}
              </p>
            </div>

            <p className="!wi-text-lg  !wi-font-bold !wi-text-secondary ">
              Keluarga Rizka{" "}
            </p>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Undangan: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {
                  tamu.filter(
                    (tamu) => tamu.tamuDari === "2" && tamu.type === "1"
                  ).length
                }
              </p>
            </div>

            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary "> Pax: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu
                  .filter((tamu) => tamu.tamuDari === "2" && tamu.type === "1")
                  .reduce((acc, tamu) => acc + tamu.pax, 0)}
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col !wi-font-buenard wi-border wi-border-primary wi-rounded-md wi-p-2">
            <p className="!wi-text-lg !wi-font-bold !wi-text-secondary ">
              Teman Adhitya{" "}
            </p>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Undangan: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {
                  tamu.filter(
                    (tamu) => tamu.tamuDari === "1" && tamu.type === "2"
                  ).length
                }
              </p>
            </div>

            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary "> Pax: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu
                  .filter((tamu) => tamu.tamuDari === "1" && tamu.type === "2")
                  .reduce((acc, tamu) => acc + tamu.pax, 0)}
              </p>
            </div>

            <p className="!wi-text-lg !wi-font-bold  !wi-text-secondary ">
              Teman Rizka{" "}
            </p>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Undangan: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {
                  tamu.filter(
                    (tamu) => tamu.tamuDari === "2" && tamu.type === "2"
                  ).length
                }
              </p>
            </div>

            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary "> Pax: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu
                  .filter((tamu) => tamu.tamuDari === "2" && tamu.type === "2")
                  .reduce((acc, tamu) => acc + tamu.pax, 0)}
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col  !wi-font-buenard wi-border wi-border-primary wi-rounded-md wi-p-2">
            <p className="!wi-text-lg !wi-font-bold !wi-text-secondary ">
              Konfirmasi Kehadiran{" "}
            </p>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Belum: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu.filter((tamu) => !tamu.confirmedHadir).length}
              </p>
            </div>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Sudah: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu.filter((tamu) => tamu.confirmedHadir).length}
              </p>
            </div>
            <p className="!wi-text-lg !wi-font-bold !wi-text-secondary ">
              Scan QR Kehadiran{" "}
            </p>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Belum: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu.filter((tamu) => !tamu.hadir).length}
              </p>
            </div>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">Sudah: </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu.filter((tamu) => tamu.hadir).length}
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col !wi-font-buenard wi-border wi-border-primary wi-rounded-md wi-p-2">
            <p className="!wi-text-lg !wi-font-bold !wi-text-secondary ">
              Undangan{" "}
            </p>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">
                Belum Dikirim:{" "}
              </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu.filter((tamu) => !tamu.undanganTerkirim).length}
              </p>
            </div>
            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary ">
                Sudah Dikirim:{" "}
              </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu.filter((tamu) => tamu.undanganTerkirim).length}
              </p>
            </div>

            <div className="wi-flex wi-flex-row wi-justify-between wi-items-center wi-w-full">
              <p className="!wi-text-lg  !wi-text-secondary !wi-font-bold ">
                Undangan Fisik:{" "}
              </p>
              <p className="!wi-text-lg !wi-text-secondary ">
                {tamu.filter((tamu) => tamu.undanganFisik).length}
              </p>
            </div>
          </div>
        </div>

        <div className="wi-flex wi-flex-col wi-gap-2 wi-w-full ">
          <div className="wi-flex wi-flex-col-reverse md:wi-flex-row wi-items-center wi-justify-between  wi-w-full ">
            <div className=" wi-flex-row wi-gap-2 wi-hidden md:wi-flex">
              <Button
                type="primary"
                className=" !wi-bg-green-500 hover:!wi-bg-green-600/80 wi-w-auto "
                onClick={() => setIsModalTambahOpen(true)}
              >
                Tambah Tamu
              </Button>
              <Button
                type="primary"
                className=" !wi-bg-blue-500 hover:!wi-bg-blue-600/80 wi-w-auto "
                onClick={() =>
                  navigate(`/${import.meta.env.VITE_MINI_PANEL}/scan`)
                }
                icon={<ScanOutlined />}
              >
                Scan QR
              </Button>
              <Button
                type="primary"
                className=" !wi-bg-primary hover:!wi-bg-secondary/80 wi-w-auto "
                onClick={() => setIsModalLihatWishOpen(true)}
              >
                Lihat Ucapan
              </Button>
              <Popover
                title="Export"
                content={
                  <ExportButtons
                    tamu={tamu}
                    statusTamu={statusTamu}
                    mempelai={mempelai}
                    handleExport={handleExport}
                    handleExportToTXT={handleExportToTXT}
                  />
                }
              >
                <Button
                  type="primary"
                  className=" !wi-bg-purple-500 hover:!wi-bg-purple-600/80 wi-w-auto "
                >
                  Export
                </Button>
              </Popover>
            </div>
            <div className=" wi-relative wi-flex wi-items-center wi-w-full md:wi-w-[450px] relative">
              <input
                type="text"
                id="search-input"
                placeholder="Cari Nama Tamu / Jumlah Pax"
                onChange={debounce((e) => setSearchValue(e.target.value), 1000)}
                className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-2 wi-w-full wi-border-none wi-bg-primary  wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
              />
              {searchValue && (
                <Button
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={
                    <CloseOutlined className="!wi-text-white !wi-text-[0.75rem]" />
                  }
                  className="!wi-bg-red-500 hover:!wi-bg-red-600/80 wi-absolute wi-right-2 "
                  onClick={() => {
                    setSearchValue("");
                    (
                      document.getElementById(
                        "search-input"
                      ) as HTMLInputElement
                    ).value = "";
                  }}
                ></Button>
              )}
            </div>
          </div>
          <div
            className={`wi-flex wi-flex-wrap    wi-justify-between wi-items-center wi-w-full wi-gap-2`}
          >
            <div className="wi-flex wi-flex-row wi-gap-2">
              <Button
                type="primary"
                className=" !wi-bg-primary hover:!wi-bg-secondary/80 wi-w-auto md:wi-hidden !wi-text-white "
                size="small"
                onClick={() => setIsModalFilterOpen(true)}
                icon={<FilterOutlined className="!wi-text-white" />}
              >
                Filter
              </Button>
              <Popover
                title="Export"
                className="md:wi-hidden "
                
                content={
                  <ExportButtons
                    tamu={tamu}
                    statusTamu={statusTamu}
                    mempelai={mempelai}
                    handleExport={handleExport}
                    handleExportToTXT={handleExportToTXT}
                  />
                }
              >
                <Button
                  size="small"
                  type="primary"
                  className=" !wi-bg-purple-500 hover:!wi-bg-purple-600/80 wi-w-auto "
                >
                  Export
                </Button>
              </Popover>
            </div>
            <p className="!wi-text-tertiary !wi-text-base">
              ({filteredTamu.length} dari {tamu.length})
            </p>

            <Radio.Group
              className="wi-flex-wrap wi-gap-2 wi-hidden md:wi-flex"
              buttonStyle="outline"
              onChange={(e) => {
                setFilterOption(e.target.value);
              }}
              value={filterOption}
            >
              {/* <Radio value="tidakAdaStatus">
                  <span className="!wi-text-base !wi-text-secondary">
                    Belum Ada Status
                  </span>
                </Radio> */}
              <Radio value="temanRizka">
                <span className="!wi-text-base !wi-text-secondary">
                  Teman Rizka
                </span>
              </Radio>
              <Radio value="temanAdhitya">
                <span className="!wi-text-base !wi-text-secondary">
                  Teman Adhitya
                </span>
              </Radio>
              <Radio value="keluargaRizka">
                <span className="!wi-text-base !wi-text-secondary">
                  Keluarga Rizka
                </span>
              </Radio>
              <Radio value="keluargaAdhitya">
                <span className="!wi-text-base !wi-text-secondary">
                  Keluarga Adhitya
                </span>
              </Radio>
              <Radio value="undanganBelumTerkirim">
                <span className="!wi-text-base !wi-text-secondary">
                  Undangan Belum Dikirim
                </span>
              </Radio>
              <Radio value="undanganTerkirim">
                <span className="!wi-text-base !wi-text-secondary">
                  Undangan Sudah Dikirim
                </span>
              </Radio>
              <Radio value="hadir">
                <span className="!wi-text-base !wi-text-secondary">Hadir</span>
              </Radio>
              <Radio value="tidakHadir">
                <span className="!wi-text-base !wi-text-secondary">
                  Tidak Hadir
                </span>
              </Radio>
              <Radio value="all">
                <span className="!wi-text-base !wi-text-secondary">Semua</span>
              </Radio>
            </Radio.Group>
          </div>
        </div>

        {loading ? (
          <div className="wi-flex wi-justify-center wi-items-center wi-mt-4 wi-h-[calc(100vh-150px)] md:wi-h-auto">
            <p className="!wi-text-secondary wi-font-buenard wi-text-lg">
              Memuat data...
            </p>
          </div>
        ) : filteredTamu.length > 0 ? (
          <>
            <div
              ref={containerRef}
              className="wi-grid wi-grid-cols-1 sm:wi-grid-cols-2 md:wi-grid-cols-3 wi-pb-[25px] lg:wi-grid-cols-4 xl:wi-grid-cols-5 wi-gap-4 wi-mt-4 wi-overflow-y-scroll wi-h-auto"
            >
              {currentTamu.map((tamu, index) => (
                <ListItem key={index} tamu={tamu} index={index} />
              ))}
            </div>
            {/* <Pagination
              className="wi-justify-center wi-mt-4  wi-hidden md:wi-flex"
              defaultCurrent={1}
              total={filteredTamu.length}
              pageSize={tamuPerPage}
              onShowSizeChange={(current, pageSize) => {
                setTamuPerPage(pageSize);
              }}
              onChange={(page) => setCurrentPage(page - 1)}
            /> */}
          </>
        ) : (
          <div className="wi-flex wi-justify-center wi-items-center wi-mt-4 wi-h-[calc(100vh-150px)] md:wi-h-auto">
            <p className="!wi-text-secondary wi-font-buenard wi-text-lg">
              Tidak ada tamu yang ditemukan
            </p>
          </div>
        )}

        <Button
          type="primary"
          className=" wi-w-[45px] wi-h-[45px] wi-flex wi-items-center wi-justify-center wi-rounded-full wi-fixed wi-bottom-2 wi-left-2 wi-z-10 !wi-bg-blue-500 hover:!wi-bg-blue-600/80  md:wi-hidden "
          shape="circle"
          onClick={() => setIsModalSummaryInfoOpen(true)}
        >
          <InfoOutlined className="!wi-text-[calc(1rem+10px)]" />
        </Button>
        <Button
          type="primary"
          className=" wi-w-[45px] wi-h-[45px] wi-flex wi-items-center wi-justify-center wi-rounded-full wi-fixed wi-bottom-2 wi-right-2 wi-z-10 !wi-bg-blue-500 hover:!wi-bg-blue-600/80  md:wi-hidden "
          shape="circle"
          onClick={() => navigate(`/${import.meta.env.VITE_MINI_PANEL}/scan`)}
        >
          <ScanOutlined className="!wi-text-[calc(1rem+10px)]" />
        </Button>
        {!isAtTop && (
          <Button
            type="primary"
            className="wi-w-[35px] wi-h-[35px] wi-flex wi-items-center wi-justify-center wi-rounded-full wi-fixed wi-bottom-[85px] wi-right-2 wi-z-10 !wi-bg-white wi-text-tertiary hover:!wi-bg-secondary/80 md:wi-hidden"
            shape="circle"
            onClick={() => {
              if (containerRef.current) {
                containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
              }
            }}
          >
            <ArrowUpOutlined className="!wi-text-[calc(0.5rem+10px)]" />
          </Button>
        )}

        <Button
          type="primary"
          className=" wi-w-[65px] wi-h-[65px] wi-flex wi-items-center wi-justify-center wi-rounded-full wi-fixed wi-bottom-2 wi-right-1/2 wi-translate-x-1/2 wi-z-10 !wi-bg-green-500 hover:!wi-bg-green-600/80  md:wi-hidden "
          shape="circle"
          onClick={() => setIsModalTambahOpen(true)}
        >
          <PlusOutlined className="!wi-text-[calc(1rem+10px)]" />
        </Button>
      </div>
      <TambahTamuModal
        isModalTambahOpen={isModalTambahOpen}
        setIsModalTambahOpen={setIsModalTambahOpen}
      />
      <SummaryInfo
        isModalSummaryInfoOpen={isModalSummaryInfoOpen}
        setIsModalSummaryInfoOpen={setIsModalSummaryInfoOpen}
        tamu={tamu}
      />
      <LiahtWishModal
        isModalLihatWishOpen={isModalLihatWishOpen}
        setIsModalLihatWishOpen={setIsModalLihatWishOpen}
        tamu={null}
      />
      <FilterTamuModal
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        isModalFilterOpen={isModalFilterOpen}
        setIsModalFilterOpen={setIsModalFilterOpen}
      />
    </>
  );
};

const ExportButtons = ({
  tamu,
  statusTamu,
  mempelai,
  handleExport,
  handleExportToTXT,
}: {
  tamu: TamuModel[];
  statusTamu: any;
  mempelai: any;
  handleExport: (fileName: string, data: any[]) => void;
  handleExportToTXT: (fileName: string, data: string[]) => void;
}) => {
  return (
    <div className="wi-flex wi-flex-col wi-gap-2">
      <Button
        type="primary"
        className=" !wi-bg-purple-500 hover:!wi-bg-purple-600/80 wi-w-auto "
        onClick={() =>
          handleExport(
            `undangan-fisik-${new Date().toLocaleDateString()}`,
            tamu
              .filter((tamu) => tamu.undanganFisik)
              .map((tamu) => ({
                nama: tamu.displayName,
                status: `${
                  statusTamu.find((item: any) => item.id === tamu.type)?.name ||
                  ""
                } ${
                  mempelai.find((item: any) => item.id === tamu.tamuDari)?.name ||
                  ""
                }`,
              }))
          )
        }
      >
        List Undangan Fisik (Excel)
      </Button>
      <Button
        type="primary"
        className=" !wi-bg-purple-500 hover:!wi-bg-purple-600/80 wi-w-auto "
        onClick={() =>
          handleExport(
            `undangan-semua-${new Date().toLocaleDateString()}`,
            tamu.map((tamu) => ({
              nama: tamu.displayName,
              status: `${
                statusTamu.find((item: any) => item.id === tamu.type)?.name || ""
              } ${
                mempelai.find((item: any) => item.id === tamu.tamuDari)?.name || ""
              }`,
            }))
          )
        }
      >
        List Semua Undangan (Excel)
      </Button>
      <Button
        type="primary"
        className=" !wi-bg-purple-500 hover:!wi-bg-purple-600/80 wi-w-auto "
        onClick={() =>
          handleExportToTXT(
            `undangan-fisik-${new Date().toLocaleDateString()}`,
            tamu
              .filter((tamu) => tamu.undanganFisik)
              .map((tamu) => tamu.displayName)
          )
        }
      >
        List Undangan Fisik (TXT)
      </Button>
      <Button
        type="primary"
        className=" !wi-bg-purple-500 hover:!wi-bg-purple-600/80 wi-w-auto "
        onClick={() =>
          handleExportToTXT(
            `undangan-fisik-${new Date().toLocaleDateString()}`,
            tamu.map((tamu) => tamu.displayName)
          )
        }
      >
        List Semua Undangan (TXT)
      </Button>
    </div>
  );
};

export default TamuList;
