import TamuModel from "@/types/TamuModel";
import {
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  InstagramOutlined,
  IssuesCloseOutlined,
  LoadingOutlined,
  QrcodeOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Card, Button, Checkbox, Modal } from "antd";
import { toast } from "react-toastify";
import EditTamuModal from "./EditTamuModal";
import { useMemo, useState } from "react";
import { db, doc, updateDoc } from "@/utility/firebase_options";
import LihatUndanganModal from "./LihatUndanganModal";
import HapusTamuModal from "./HapusTamuModel";
import { mempelai, statusTamu } from "@/constants/mempelai";
import LihatWishModal from "./LiahtWishModal";
import LihatQRCodeModal from "./LihatQRCodeModal";

const ListItem = ({ tamu, index }: { tamu: TamuModel; index: number }) => {
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalLihatUndanganOpen, setIsModalLihatUndanganOpen] =
    useState(false);
  const [isModalLihatQRCodeOpen, setIsModalLihatQRCodeOpen] = useState(false);
  const [isModalLihatWishOpen, setIsModalLihatWishOpen] = useState(false);
  const [isModalHapusTamuOpen, setIsModalHapusTamuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fisikLoading, setFisikLoading] = useState(false);
  const generateInvitationMessage = (tamu: TamuModel) => {
    return `Kepada Yth, Bapak/Ibu/Saudara/i.\n\n*${tamu.displayName} dan Partner*\n\nDengan segala hormat, kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri acara pernikahan kami:\n\n*Adhitya Rachman Hidayatullah dan Rizka Agustin*\n\nBerikut adalah link undangan kami yang berisi informasi lengkap mengenai acara tersebut:\n\n${window.location.origin}/?to=${tamu.id}\n\nMerupakan suatu kehormatan dan kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu pada acara pernikahan kami.\n\nAtas perhatian dan kehadirannya, kami ucapkan terima kasih yang sebesar-besarnya.`;
  };
  const sendInvitation = (tamu: TamuModel) => {
    const message = generateInvitationMessage(tamu);
    const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };
  const shareOnInstagram = (tamu: TamuModel) => {
    const message = generateInvitationMessage(tamu);
    const url = `https://www.instagram.com/direct/new/`;
    navigator.clipboard
      .writeText(message.replace(/\*/g, ""))
      .then(() => {
        window.open(url, "_blank");
      })
      .catch((err) => {
        toast.error("Error copying text: ", err);
      });
  };
  // const sendTelegramInvitation = (tamu: TamuModel) => {
  //   const message = generateInvitationMessage(tamu);
  //   const url = `https://t.me/${import.meta.env.VITE_TELEGRAM_USERNAME}?text=${encodeURIComponent(message)}`;
  //   window.open(url, "_blank");
  // };

  const copyInvitationLink = (tamu: TamuModel) => {
    const message = generateInvitationMessage(tamu);
    navigator.clipboard
      .writeText(message.replace(/\*/g, ""))
      .then(() => {
        toast.success(`Undangan ${tamu.displayName} telah disalin`);
      })
      .catch((err) => {
        toast.error("Error copying text: ", err);
      });
  };

  const handleFisikCheckboxChange = (tamu: TamuModel, checked: boolean) => {
    setFisikLoading(true);
    const docRef = doc(db, "tamu", tamu.id ?? "");
    updateDoc(docRef, {
      undanganFisik: checked,
    })
      .then(() => {
        setFisikLoading(false);
        if (checked) {
          toast.success(`Tamu ${tamu.displayName} Undangan fisik`);
        }
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setFisikLoading(false);
      });
  };  

  const handleCheckboxChange = (tamu: TamuModel, checked: boolean) => {
    setLoading(true);
    const docRef = doc(db, "tamu", tamu.id ?? "");
    updateDoc(docRef, {
      undanganTerkirim: checked,
    })
      .then(() => {
        setLoading(false);
        if (checked) {
          toast.success(`Status undangan sudah dikirim ke ${tamu.displayName}`);
        }
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setLoading(false);
      });
  };
  const status = useMemo(() => {
    const d = mempelai.find((item) => item.id === tamu.tamuDari)?.name || "";
    const s = statusTamu.find((item) => item.id === tamu.type)?.name || "";
    if (!tamu.type && !tamu.tamuDari) {
      return "-";
    }
    return `${s} ${d}`;
  }, [tamu]);

  const handleAssistHadir = (t: TamuModel) => {
    Modal.confirm({
      title: "Tamu Hadir",
      okButtonProps: {
        className: "!wi-bg-green-500 hover:!wi-bg-green-500/80",
      },
      onOk: () => {
        const docRef = doc(db, "tamu", t.id ?? "");
        updateDoc(docRef, {
          hadir: true,
          confirmedHadir: true,
          updatedAt: new Date().toISOString(),
        })
          .then(() => {
            setLoading(false);
            toast.success(
              `Status kehadiran ${t.displayName} telah dikonfirmasi`
            );
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
            setLoading(false);
          });
      },
      okText: "Ya",
      cancelText: "Tidak",
      content: `Apakah Anda yakin ingin menyatakan tamu ${tamu.displayName} menghadiri acara?`,
    });
  };

  return (
    <>
      <Card
        key={index}
        className="wi-flex wi-flex-col  wi-w-full   wi-rounded-lg wi-bg-kramik wi-bg-cover wi-border-none wi-shadow-md "
      >
        <p className="!wi-text-[1.5rem] wi-font-semibold !wi-text-white wi-mb-2">
          {tamu.displayName}
        </p>
        <div className="wi-flex wi-flex-row wi-items-center wi-gap-2  wi-justify-between wi-w-full">
          <p className="!wi-text-base !wi-text-primary">Pax:</p>
          <p className="!wi-text-base !wi-text-primary">{tamu.pax} Orang</p>
        </div>
        <div className="wi-flex wi-flex-row wi-items-center wi-gap-2  wi-justify-between wi-w-full">
          <p className="!wi-text-base !wi-text-primary">Status:</p>
          <p className="!wi-text-base !wi-text-primary">{status}</p>
        </div>
        <div className="wi-flex wi-flex-row wi-items-center wi-gap-2  wi-justify-between wi-w-full">
          <p className="!wi-text-base !wi-text-primary">
            Konfirmasi Kehadiran:{" "}
          </p>
          <p className="!wi-text-base !wi-text-primary">
            {tamu.confirmedHadir ? (
              <CheckOutlined className="wi-text-green-500" />
            ) : (
              <CloseOutlined className="wi-text-red-500" />
            )}
          </p>
        </div>
        <div className="wi-flex wi-flex-row wi-items-center wi-gap-2  wi-justify-between wi-w-full">
          <p className="!wi-text-base !wi-text-primary">QR Scan:</p>
          <p className="!wi-text-base !wi-text-primary">
            {tamu.hadir ? (
              <CheckOutlined className="wi-text-green-500" />
            ) : (
              <CloseOutlined className="wi-text-red-500" />
            )}
          </p>
        </div>
        <div className="wi-flex wi-flex-row wi-gap-2 wi-w-full wi-mt-2">
          <Button
            type="primary"
            icon={<EditOutlined />}
            className="!wi-bg-yellow-500 hover:!wi-bg-yellow-500/80 wi-w-full "
            onClick={() => setIsModalEditOpen(true)}
          >
            Ubah
          </Button>

          <Button
            type="primary"
            className="!wi-bg-red-500 hover:!wi-bg-red-500/80 wi-w-full "
            icon={<DeleteOutlined />}
            onClick={() => setIsModalHapusTamuOpen(true)}
          >
            Hapus
          </Button>
        </div>
      
        <div className="wi-flex wi-flex-row wi-gap-2 wi-w-full wi-mt-2">
          <Button
            type="primary"
            icon={<QrcodeOutlined />}
            className="!wi-bg-blue-500 hover:!wi-bg-blue-500/80 wi-w-full"
            onClick={() => setIsModalLihatQRCodeOpen(true)}
          >
            QR Code
          </Button>

          <Button
            type="primary"
            className="!wi-bg-orange-500 hover:!wi-bg-orange-500/80 wi-w-full"
            icon={<IssuesCloseOutlined />}
            onClick={() => handleAssistHadir(tamu)}
          >
           Tamu Hadir 
          </Button>
        </div>
        <p className="wi-text-base wi-w-full  wi-mt-2 ">Ucapan:</p>
        <Button
          type="primary"
          icon={<CommentOutlined />}
          className="!wi-bg-primary hover:!wi-bg-primary/80 wi-w-full wi-mt-2"
          onClick={() => setIsModalLihatWishOpen(true)}
        >
          Lihat
        </Button>
        <p className="wi-text-base wi-w-full  wi-mt-2 ">Undangan:</p>
        <div className="wi-flex wi-flex-row wi-gap-2 wi-w-full wi-mt-2">
          <Button
            type="primary"
            className="!wi-bg-blue-500 hover:!wi-bg-blue-500/80 wi-w-full"
            icon={<EyeOutlined />}
            onClick={() => setIsModalLihatUndanganOpen(true)}
          >
            Lihat
          </Button>

          <Button
            type="primary"
            className="!wi-bg-purple-500 hover:!wi-bg-purple-500/80 wi-w-full"
            icon={<CopyOutlined />}
            onClick={() => copyInvitationLink(tamu)}
          >
            Salin
          </Button>
        </div>

        <Button
          type="primary"
          className="!wi-bg-green-500 hover:!wi-bg-green-500/80 wi-w-full wi-mt-2"
          icon={<WhatsAppOutlined />}
          onClick={() => sendInvitation(tamu)}
        >
          Bagikan via Whatsapp
        </Button>
        <Button
          type="primary"
          className="!wi-bg-pink-500 hover:!wi-bg-pink-500/80 wi-w-full wi-mt-2"
          icon={<InstagramOutlined />}
          onClick={() => shareOnInstagram(tamu)}
        >
          Bagikan via Instagram
        </Button>
        {/* <Button
          type="primary"
          className="!wi-bg-blue-500 hover:!wi-bg-blue-500/80 wi-w-full wi-mt-2"
          onClick={() => sendTelegramInvitation(tamu)}
        >
          Bagikan via Telegram
        </Button> */}
        <p className="!wi-text-base wi-w-full  wi-mt-4 ">
           Undangan Fisik?
        </p>
        <div className="wi-flex wi-justify-start wi-items-center ">
          {fisikLoading ? (
            <LoadingOutlined className="wi-text-primary wi-animate-spin wi-text-2xl" />
          ) : (
            <Checkbox
              checked={tamu.undanganFisik}
              onChange={(e) => handleFisikCheckboxChange(tamu, e.target.checked)}
            >
              <span className="wi-text-base !wi-text-primary">
                Undangan Fisik
              </span>
            </Checkbox>
          )}
        </div>
        <p className="!wi-text-base wi-w-full  wi-mt-4 ">
          Sudah Kirim Undangan?
        </p>
        <div className="wi-flex wi-justify-start wi-items-center ">
          {loading ? (
            <LoadingOutlined className="wi-text-primary wi-animate-spin wi-text-2xl" />
          ) : (
            <Checkbox
              checked={tamu.undanganTerkirim}
              onChange={(e) => handleCheckboxChange(tamu, e.target.checked)}
            >
              <span className="wi-text-base !wi-text-primary">
                Sudah Dikirim
              </span>
            </Checkbox>
          )}
        </div>
      </Card>
      <EditTamuModal
        tamu={tamu}
        isModalEditOpen={isModalEditOpen}
        setIsModalEditOpen={setIsModalEditOpen}
      />
      <LihatUndanganModal
        copyInvitationLink={copyInvitationLink}
        isModalLihatUndanganOpen={isModalLihatUndanganOpen}
        setIsModalLihatUndanganOpen={setIsModalLihatUndanganOpen}
        tamu={tamu}
      />
      <HapusTamuModal
        isModalHapusTamuOpen={isModalHapusTamuOpen}
        setIsModalHapusTamuOpen={setIsModalHapusTamuOpen}
        tamu={tamu}
      />
      <LihatWishModal
        isModalLihatWishOpen={isModalLihatWishOpen}
        setIsModalLihatWishOpen={setIsModalLihatWishOpen}
        tamu={tamu}
      />
      <LihatQRCodeModal
        isModalLihatQRCodeOpen={isModalLihatQRCodeOpen}
        setIsModalLihatQRCodeOpen={setIsModalLihatQRCodeOpen}
        tamu={tamu}
      />
    </>
  );
};

export default ListItem;
